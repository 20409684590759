export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Director</h2>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
          <div className="about-text" >
          <p >
            
            Hello and welcome! I am <strong>Wahab</strong> , father of three beautiful kids, on a mission to make your residence ,<strong><q>HOME</q></strong>. I’m  profoundly honoured and humbled that you are
            considering using our services. Since <strong>2010</strong>, I had the
            privilege to meet and work with thousands of amazing homeowners like
            yourself. Besides the birth of my children, there is nothing more
            satisfying than seeing a homeowner’s life change when we have
            transformed their dream home into a masterpiece. For that reason, we
            take our work very seriously and are committed to our cause. At <strong>AU Painting and Renovations</strong> our mission is to paint homeowners’ dreams, and make your
            painting experience last a lifetime! I look forward to hopefully
            meeting you one day, as it brings me stupendous happiness to make
            homeowner’s dreams come true.
          </p>
          </div>
        </div>
        {/* <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div> */}
      </div>
    </div>
  );
};
